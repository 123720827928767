
import { defineComponent } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  name: 'ServicesDownloads',
  components: {
    TmButton,
    PageContent,
  },
  setup() {
    const items = [
      {
        name: 'Desktop',
        img: 'desktop.svg',
        text: 'Textmagic for Desktop',
        link: 'https://textmagic-storage.s3.amazonaws.com/prod/downloads/TextMagic+Setup.dmg',
      },
      {
        name: 'Android',
        img: 'android.svg',
        text: 'Textmagic for Android',
        link: 'https://play.google.com/store/apps/details?id=textmagic.com.textmagicmessenger&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
        linkImg: 'android-download-btn.svg',
      },
      {
        name: 'iOS',
        img: 'ios.svg',
        text: 'Textmagic for iOS',
        link: 'https://itunes.apple.com/us/app/textmagic/id1107866161',
        linkImg: 'ios-download-btn.svg',
      },
    ]

    return {
      items,
    }
  },
})
